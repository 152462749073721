/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Account } from "./Account";

export class ExclusionComment {
  id: number;
  comment: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

export class Limit {
  id: number;

  type: string;

  hours: number;

  amount: number;

  active: number;

  account: Account;

  exclusionComment: ExclusionComment;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;
}
