/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FunctionComponent,
  useEffect,
  useState,
  Component,
} from "react";
import { Game } from "../../../models/app/Game";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Form, Tabs, Tab } from "react-bootstrap";
import GameCategorySelector from "../GameCategorySelector/GameCategorySelector";
import { GameCategory } from "../../../models/app/GameCategory";
import { Category } from "../../../models/app/Category";
import { Provider } from "../../../models/app/Provider";
import toastr from "toastr";
import urls from "../../../utilities/urls";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  RequestGameThumbnailDto,
  RequestGetGamesDto,
  ResponseGameThumbnailDto,
  ResponseGetGamesDto,
  UpdateGameDto,
} from "src/models/dto/game.dto";
import {
  ResponseGetCategoryDto,
  RequestGetCategoryDto,
} from "src/models/dto/category.dto";
import { GameCategoriesList } from "../GameCategoriesList/GameCategoriesList";

import DayJS from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

const GameEditPage: FunctionComponent = () => {
  DayJS.extend(utc);
  DayJS.extend(tz);

  const [game, setGame] = useState<Game>(new Game());
  const { gameId } = useParams<{ gameId: string }>();

  const [providers, setProviders] = useState<Provider[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [chosenCategories, setChosenCategories] = useState<Category[]>([]);

  const [thumbnail, setThumbnail] = useState<string | boolean>();

  const [file, setFile] = useState<any>();

  const getGame = async (): Promise<void> => {
    try {
      const response: ResponseGetGamesDto = await makeRequest<
        ResponseGetGamesDto,
        RequestGetGamesDto
      >("post", `${urls.GAMES_LIST}`, {
        where: { id: [Number(gameId)] },
      });

      const game = response.games[0];

      setGame(game);
      const chosenCats: Category[] = game.gameCategories.map(
        (gameCategory: GameCategory): Category => gameCategory.category,
      );

      setChosenCategories(chosenCats);

      const thumbnailRes = await makeRequest<
        ResponseGameThumbnailDto,
        RequestGameThumbnailDto
      >("post", urls.GAMES_GET_THUMBNAIL, {
        provider: game.provider.name.toString(),
        externalId: game.externalId,
      });

      setThumbnail(thumbnailRes.imageUrl);
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  useEffect(() => {
    getGame();
  }, [gameId]);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        const categoryResponse = await makeRequest<
          ResponseGetCategoryDto,
          RequestGetCategoryDto
        >("post", urls.CATEGORIES_LIST);
        setCategories(categoryResponse.categories);

        const providers = await makeRequest<Provider[], any>(
          "post",
          urls.PROVIDERS,
        );

        setProviders(providers);
      } catch (err) {
        toastr.error((err as any).message);
      }
    };

    getData();
  }, []);

  const getProviderById = (providerId: number): Provider =>
    providers.filter((provider: Provider) => provider.id === providerId)[0];

  const checkData = () => {
    if (game.rtp) {
      if (game.rtp > 99.99) {
        toastr.error("RTP should be lesser than 99.99");
        return false;
      }
    }
    return true;
  };

  const saveGame = async (): Promise<void> => {
    if (checkData()) {
      const gameCategoriesIds: number[] = chosenCategories.map(
        (category: Category) => {
          return category.id;
        },
      );
      try {
        await makeRequest<Game, UpdateGameDto>("post", `${urls.GAMES_UPDATE}`, {
          id: game.id,
          active: game.active,
          code: game.code,
          name: game.name,
          rating: game.rating,
          providerWeight: game.providerWeight,
          releasedAt: game.releasedAt,
          publicReleaseAt: game.publicReleaseAt,
          gameCategorieIds: gameCategoriesIds,
          primaryCategoryId: game.primaryCategory.id,
          providerId: game.provider.id,
          rtp: game.rtp,
        });

        toastr.success("Game Updated!");
      } catch (err) {
        toastr.error((err as any).message);
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile && selectedFile.name === "thumbnail.png") {
      setFile(selectedFile);
    } else {
      toastr.error('Please upload a file named "thumbnail.png".');
      event.target.value = "";
    }
  };

  const uploadThumbnail = async (): Promise<void> => {
    if (file?.name === "thumbnail.png") {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("provider", game.provider.name.toString());
        formData.append("externalId", game.externalId.toString());

        const upload = await makeRequest<ResponseGameThumbnailDto, any>(
          "post",
          urls.GAMES_UPLOAD_THUMBNAIL,
          formData,
        );
        setThumbnail(upload.imageUrl);
        toastr.success(`${file.name} uploaded!`);
      } catch (e) {
        const error: { code: string; message: string } = e as any;
        toastr.error(error.code, error.message);
      }
    }
  };

  type GameContentProps = {
    game: Game;
    refreshData: () => void;
  };

  class GameCategoriesContent extends Component<GameContentProps, {}> {
    render(): React.ReactChild {
      if (!this.props.game) {
        return <></>;
      }
      return (
        <GameCategoriesList
          game={this.props.game}
          refreshData={this.props.refreshData}
        />
      );
    }
  }
  const values: number[] = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

  return (
    <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
      <Tab eventKey="details" title="Details">
        <Card>
          <Card.Header>
            {gameId === undefined ? "Create" : "Edit"} Game
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="formName">
                  <Form.Label>Game Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter game name"
                    value={game.name}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void => setGame({ ...game, name: event.target.value })}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formCode">
                  <Form.Label>Game Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter code"
                    value={game.code}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void =>
                      setGame({
                        ...game,
                        code: event.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGameCategories">
                  <Form.Label>Game Categories</Form.Label>
                  <GameCategorySelector
                    game={game}
                    onChangeCategories={(
                      categoryIdentifiers: Category[],
                    ): void => setChosenCategories(categoryIdentifiers)}
                    categories={categories}
                    chosenCategories={chosenCategories}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGamePrimaryCode">
                  <Form.Label>Game Primary Category</Form.Label>
                  <GameCategorySelector
                    game={game}
                    categories={categories}
                    defaultValue={0}
                    onChangeCategories={(category: Category[]): void =>
                      setGame({ ...game, primaryCategory: category[0] })
                    }
                    chosenCategories={
                      game.primaryCategory === undefined
                        ? []
                        : [game.primaryCategory]
                    }
                    singleSelect={true}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="exampleForm.SelectCustom">
                  <Form.Label>Provider Id</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={game.provider?.id}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        const newProviderId: number = parseInt(
                          evt.target.value,
                        );
                        setGame({
                          ...game,
                          provider: getProviderById(newProviderId),
                        });
                      }
                    }}
                  >
                    {providers.map((provider: Provider) => (
                      <option key={provider.id} value={provider.id}>
                        {provider.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group style={{ width: "50%" }} controlId="formExternalId">
                  <Form.Label>Game External Id</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder="Enter external id"
                    value={game.externalId}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void =>
                      setGame({
                        ...game,
                        externalId: event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0.2rem 2rem",
                    margin: "0",
                  }}
                >
                  <Form.Label className="text-base">
                    If Game is a VIP early release game, Select VIP early
                    release date and normal(site-wide) release date and the Game
                    will automatically realease for everyone on the chosen
                    release date.
                  </Form.Label>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formCode">
                  <Form.Label>Game Rating</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={game?.rating}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setGame({
                        ...game,
                        rating: Number(evt.target.value),
                      });
                    }}
                  >
                    {values.map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formRtp">
                  <Form.Label>Game RTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter RTP of the game"
                    value={game.rtp}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void =>
                      setGame({ ...game, rtp: Number(event.target.value) })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="text-base">Release Date</Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={
                      game.publicReleaseAt
                        ? DayJS(game.publicReleaseAt).format("YYYY-MM-DD")
                        : game.releasedAt
                        ? DayJS(game.releasedAt).format("YYYY-MM-DD")
                        : ""
                    }
                    // added 3 hours to release date to stop dropping hours for summer time shift
                    onChange={(evt: any): void => {
                      if (evt.target) {
                        setGame({
                          ...game,
                          publicReleaseAt: DayJS(evt.target.value, "YYYY-MM-DD")
                            .add(3, "h")
                            .toDate(),
                        });
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="text-base">
                    VIP: Early Release Date
                  </Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={
                      game.releasedAt
                        ? DayJS(game.releasedAt).format("YYYY-MM-DD")
                        : ""
                    }
                    // added 3 hours to release date to stop dropping hours for summer time shift
                    onChange={(evt: any): void => {
                      if (evt.target) {
                        setGame({
                          ...game,
                          releasedAt: DayJS(evt.target.value, "YYYY-MM-DD")
                            .add(3, "h")
                            .toDate(),
                        });
                      }
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row
                style={{
                  display: "grid",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Form.Group
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    style={{
                      transform: "scale(1.2)",
                      padding: "1rem",
                      paddingLeft: "2rem",
                    }}
                    type="checkbox"
                    label="Active"
                    checked={game.active === 1}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        setGame({
                          ...game,
                          active: evt.target.checked ? 1 : 0,
                        });
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group
                  style={{
                    display: "grid",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Form.File.Label>
                    Thumbnail (must be &#34;thumbnail.png&#34;)
                  </Form.File.Label>
                  <Form.File.Input
                    accept={"image/png"}
                    onChange={handleFileChange}
                    multiple={false}
                  />
                  <Form.Label>
                    {!thumbnail ? (
                      "No thumbnail found, please upload thumbnail"
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            width: "52%",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          Uploaded Thumbnail:
                        </span>
                        <img
                          width={160}
                          height={160}
                          style={{ padding: "10px" }}
                          src={thumbnail.toString()}
                          alt="Game Thumbnail"
                        />
                      </div>
                    )}
                  </Form.Label>
                  <Button
                    variant="primary"
                    style={{
                      marginLeft: "4rem",
                      marginRight: "4rem",
                      marginTop: "1rem",
                    }}
                    onClick={uploadThumbnail}
                  >
                    Upload
                  </Button>
                </Form.Group>
              </Form.Row>

              <Button variant="primary" onClick={saveGame}>
                Save
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Tab>
      {game && game.id && (
        <Tab eventKey="categories" title="Game Categories">
          <GameCategoriesContent game={game} refreshData={getGame} />
        </Tab>
      )}
    </Tabs>
  );
};

export default GameEditPage;
