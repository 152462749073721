import React, { FunctionComponent } from "react";
import { Form, Card } from "react-bootstrap";
import { makeRequest } from "../../utilities/axio.helper";
import urls from "../../utilities/urls";
import { MAX_FILE_SIZE } from "../../pages/Accounts/KYC/KYC";
import { IStatusLogsProps } from "../../pages/Accounts/StatusLogs/StatusLogs";

const FileUpload: FunctionComponent<IStatusLogsProps> = ({
  account,
  documentType,
  refreshAccount,
}) => {
  return (
    <Card.Body className="text-center pt-2">
      <Form.File custom>
        <Form.File.Label>Click to upload document</Form.File.Label>
        <Form.File.Input
          onChange={async (event: any) => {
            const file = event.target.files[0];
            try {
              const formData = new FormData();
              formData.append("file", file);
              formData.append("accountId", account.id.toString());

              if (file.size > MAX_FILE_SIZE) {
                toastr.error(
                  `Maximum file size is ${MAX_FILE_SIZE / (1024 * 1024)} MB`,
                  "FILE SIZE LIMIT EXCEEDED ",
                );
                return;
              }
              const url = `${urls.DOCUMENTS_UPLOAD}?documentType=${
                documentType || ""
              }`;

              await makeRequest<any>("post", url, formData);
              toastr.success(`${file.name} uploaded!`);

              // Refresh list
              refreshAccount && refreshAccount();
            } catch (e) {
              const error: { code: string; message: string } = e as any;
              toastr.error(error.code, error.message);
            }
          }}
        />
      </Form.File>
    </Card.Body>
  );
};

export default FileUpload;
