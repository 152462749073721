import React, { FunctionComponent } from "react";
import { Nav, Navbar as ReactNavbar, Button } from "react-bootstrap";
import { Role, RoleAccessLevel } from "src/models/admin/Role";
import Logo from "../logo/Logo";

interface NavbarProps {
  setLoggedIn: (loggedIn: boolean) => void;
  accountHighestRole: Role;
}
const navs = [
  {
    name: "Accounts",
    link: "/accounts",
    minimumRequiredAccess: RoleAccessLevel.CustomerSupport,
  },
  {
    name: "Comments",
    link: "/comments",
    minimumRequiredAccess: RoleAccessLevel.CustomerSupport,
  },
  {
    name: "Documents",
    link: "/documents",
    minimumRequiredAccess: RoleAccessLevel.CustomerSupport,
  },
  {
    name: "Games",
    link: "/games",
    minimumRequiredAccess: RoleAccessLevel.Developer,
  },
  {
    name: "Categories",
    link: "/categories",
    minimumRequiredAccess: RoleAccessLevel.Developer,
  },
  {
    name: "Providers",
    link: "/providers",
    minimumRequiredAccess: RoleAccessLevel.Developer,
  },
  {
    name: "Roles",
    link: "/backoffice-accounts",
    minimumRequiredAccess: RoleAccessLevel.Admin,
  },
  {
    name: "Transactions",
    link: "/transactions",
    minimumRequiredAccess: RoleAccessLevel.PaymentAdministrator,
  },
];

const Navbar: FunctionComponent<NavbarProps> = ({
  setLoggedIn,
  accountHighestRole,
}: NavbarProps) => {
  const getNavs = (role: Role) => {
    if (!role.accessLevel) {
      // AccessLevel not set up
      return <></>;
    }

    return navs
      .filter((nav) => role.accessLevel >= nav.minimumRequiredAccess)
      .map((navConfig, index) => {
        return (
          <Nav.Item key={index}>
            <Nav.Link href={navConfig.link}>
              <h6>{navConfig.name}</h6>
            </Nav.Link>
          </Nav.Item>
        );
      });
  };
  return (
    <ReactNavbar className="d-flex justify-content-between w-100">
      <Logo size={"8rem"} />
      <div className="d-flex flex-column justify-content-center">
        <ReactNavbar.Brand className="text-center">
          <h3>Casino Loke SE - Back Office</h3>
        </ReactNavbar.Brand>
        <Nav>{getNavs(accountHighestRole)}</Nav>
      </div>

      <div>
        <Button
          className="float-right mr-3 mt-2"
          onClick={(): void => {
            localStorage.removeItem("session-token");
            setLoggedIn(false);
          }}
        >
          Logout
        </Button>
      </div>
    </ReactNavbar>
  );
};

export default Navbar;
