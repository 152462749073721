import React, { FunctionComponent, useState } from "react";
import { Account } from "src/models/app/Account";
import { Table, Button, Card, Form } from "react-bootstrap";
import { Limit } from "src/models/app/Limit";
import { AccountLimitItem } from "./AccountLimitItem";
import { AccountLimitCreateModal } from "./AccountLimitCreateModal";
import { Comment } from "src/models/app/Comment";

export interface IAccountLimitsProps {
  account: Account;
  refreshAccount: () => void;
  onCommentUpdated: (comment: Comment) => void;
}

export const AccountLimits: FunctionComponent<IAccountLimitsProps> = (
  props: IAccountLimitsProps,
) => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const showCreateLimitModal = (): void => setShowCreateModal(true);
  const hideCreateLimitModal = (): void => setShowCreateModal(false);

  const onSaveLimit = () => props.refreshAccount();

  const [limitType, setLimitType] = useState<string>("ALL");

  const getCreateLimitModal = (): React.ReactChild => {
    if (showCreateModal && props.account) {
      return (
        <AccountLimitCreateModal
          account={props.account}
          removeModal={hideCreateLimitModal}
          onCommentUpdated={(comment: Comment) =>
            props.onCommentUpdated(comment)
          }
          onSave={onSaveLimit}
        />
      );
    }

    return <></>;
  };

  const limitTypeDropdown = (): React.ReactChild => {
    return (
      <Form.Control
        as="select"
        custom
        value={limitType}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
          setLimitType(evt.target.value);
        }}
      >
        <option value="ALL">ALL</option>
        <option value="DEPOSIT">DEPOSIT</option>
        <option value="SESSION">SESSION</option>
        <option value="EXCLUSION">EXCLUSION</option>
      </Form.Control>
    );
  };

  const onDeletedLimit = (): void => {
    props.refreshAccount();
  };

  const onUpdatedLimit = (): void => {
    props.refreshAccount();
  };

  return (
    <>
      <Card>
        <Card.Header>
          Account Limits
          <Button
            className="float-right"
            variant="primary"
            onClick={showCreateLimitModal}
          >
            Create Limit
          </Button>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Type</th>
                <th>Active</th>
                <th>Amount</th>
                <th>Hours</th>
                <th>Comment</th>
                <th>Created At</th>
                <th>Last updated</th>
                <th>End Date</th>
                <th>Options</th>
              </tr>
              <tr>
                <th className="border-0"></th>
                <th className="border-0 text-last-center">
                  {limitTypeDropdown()}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.account.limits.map(
                (limit: Limit) =>
                  (limitType === "ALL" || limitType === limit.type) && (
                    <AccountLimitItem
                      key={limit.id}
                      limit={limit}
                      account={props.account}
                      onDeleted={onDeletedLimit}
                      onUpdated={onUpdatedLimit}
                    />
                  ),
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {getCreateLimitModal()}
    </>
  );
};
