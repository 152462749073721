import React, { FunctionComponent, useEffect, useState } from "react";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";
import { ButtonGroup, Button } from "react-bootstrap";
import { Limit } from "src/models/app/Limit";
import DayJS from "dayjs";
import Swal, { SweetAlertResult } from "sweetalert2";
import { RequestUpsertLimitDto } from "src/models/dto/limit.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { AccountLimitUpdateModal } from "./AccountLimitUpdateModal";

export interface IAccountLimitItemProps {
  account: Account;
  limit: Limit;
  onUpdated: (limit: Limit) => void;
  onDeleted: (limit: Limit) => void;
}

export const AccountLimitItem: FunctionComponent<IAccountLimitItemProps> = (
  props: IAccountLimitItemProps,
) => {
  const isLimitActive = (limit: Limit): boolean => limit.active === 0;
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [limit, setLimit] = useState<Limit>(new Limit());

  const showUpdateLimitModal = (): void => setShowUpdateModal(true);
  const hideUpdateLimitModal = (): void => setShowUpdateModal(false);

  useEffect(() => {
    setLimit(props.limit);
  }, [props.limit]);

  const getUpdateLimitModal = (): React.ReactChild => {
    if (showUpdateModal && props.limit) {
      return (
        <AccountLimitUpdateModal
          account={props.account}
          limit={props.limit}
          removeModal={hideUpdateLimitModal}
          onUpdate={props.onUpdated}
        />
      );
    }

    return <></>;
  };

  const toggleLimit = async (active: boolean): Promise<void> => {
    let title = "Are you sure you want to deactivate this limit?";
    if (active) {
      title = "Are you sure you want to activate this limit?";
    }

    const result: SweetAlertResult = await Swal.fire({
      title: title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });

    if (result.isConfirmed) {
      try {
        limit.active = active ? 1 : 0;
        await makeRequest<Limit, RequestUpsertLimitDto>("post", urls.LIMITS, {
          limit,
        });

        if (active) {
          toastr.success("Activated Limit!");
        } else {
          toastr.success("Deactivated Limit!");
        }

        props.onDeleted(props.limit);
      } catch (err) {
        toastr.error((err as any).message);
      }
    }
  };

  const formatDates = (
    hours: number,
    type: string,
    createdDate?: Date,
    updatedDate?: Date,
  ): any => {
    const isExclusion = type === "EXCLUSION" ? true : false;

    return (
      <>
        <td>
          {createdDate !== null
            ? DayJS(createdDate).format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </td>
        <td>
          {updatedDate !== null
            ? DayJS(updatedDate).format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </td>
        <td>
          {createdDate !== null && isExclusion
            ? DayJS(createdDate)
                .add(hours, "hour")
                .format("YYYY-MM-DD HH:mm:ss")
            : ""}
        </td>
      </>
    );
  };

  const limitButtons = (limitType: string): any => {
    return (
      <>
        {limitType === "EXCLUSION" ? (
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            {!isLimitActive(limit) ? (
              <Button variant="danger" onClick={() => toggleLimit(false)}>
                Deactivate
              </Button>
            ) : (
              <Button variant="primary" onClick={() => toggleLimit(true)}>
                Activate
              </Button>
            )}
          </ButtonGroup>
        ) : (
          <></>
        )}
        {limit.type === "SESSION" && (
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            <Button variant="info" onClick={showUpdateLimitModal}>
              Update
            </Button>
          </ButtonGroup>
        )}
      </>
    );
  };

  const comment = props.limit.exclusionComment
    ? props.limit.exclusionComment.comment ?? ""
    : "";

  return (
    <>
      <tr key={props.limit.id}>
        <td>{props.limit.id}</td>
        <td>
          {props.limit.type}
          {isLimitActive(props.limit) ? " (Deactivated)" : ""}
        </td>
        <td>{props.limit.active}</td>
        <td>{props.limit.amount}</td>
        <td>{props.limit.hours}</td>
        <td>{comment}</td>
        {formatDates(
          props.limit.hours,
          props.limit.type,
          props.limit.createdAt,
          props.limit.updatedAt,
        )}
        <td>{limitButtons(limit.type)}</td>
      </tr>
      {getUpdateLimitModal()}
    </>
  );
};
