import React, { FunctionComponent, useState } from "react";

import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { BackOfficeAccount } from "src/models/admin/BackOfficeAccount";
import { Role } from "src/models/admin/Role";
import Multiselect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

export interface IBackofficeAccountsListItem {
  account: BackOfficeAccount;
  roles: Role[];
}

async function updateRoles(id: number, roles: Role[]) {
  try {
    const updatedRoles = await makeRequest<Role[]>(
      "post",
      urls.BACK_OFFICE_ACCOUNT_UPDATE_ROLES,
      {
        adminPassword: "8f46a3bc-46e5-4c93-adb8-3f45a6a120bd",
        id,
        roles,
      },
    );
    toastr.success("Roles Updated!");
    return updatedRoles;
  } catch (err) {
    toastr.error(err as any);
  }
}

export const BackofficeAccountsListItem: FunctionComponent<
  IBackofficeAccountsListItem
> = (props: IBackofficeAccountsListItem) => {
  const options = props.roles.map((accountRole): Option => {
    return { label: accountRole.name, value: accountRole.id };
  });
  const initRolesOptions = options.filter((option) =>
    props.account.roles.find((role) => role.id === option.value),
  );
  const [chosenRoles, setChosenRoles] = useState<Option[]>(initRolesOptions);

  return (
    <>
      <tr key={props.account.id}>
        <td>{props.account.id}</td>
        <td>{props.account.name}</td>
        <td>{props.account.email}</td>
        <td>{props.account.active}</td>
        <td>
          <Multiselect
            onChange={async (selectedOptions: Option[]) => {
              await updateRoles(
                props.account.id,
                selectedOptions.map((option) => option.value),
              );
              setChosenRoles(selectedOptions);
            }}
            value={chosenRoles}
            options={options}
            labelledBy={"Primary Category"}
            disableSearch
          />
        </td>
      </tr>
    </>
  );
};
