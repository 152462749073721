/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Tab,
  Tabs,
  Badge,
  Row,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from "react-bootstrap";
import toastr from "toastr";
import { Account } from "../../models/app/Account";

import { Language } from "../../models/app/Languages";
import { Country } from "../../models/app/Country";
import { Currency } from "../../models/app/Currency";
import urls from "../../utilities/urls";
import { makeRequest } from "../../utilities/axio.helper";
import DatePicker from "react-date-picker";
import DayJS from "dayjs";
import {
  RegisteredBrands,
  RequestGetAccountDto,
  RequestGetAccountLoginInfoDto,
  RequestGetAccountStatusDto,
  RequestSetAccountStatusDto,
  RequestUpsertAccountDto,
  ResponseGetAccountDto,
  ResponseGetAccountLoginInfoDto,
  ResponseGetAccountStatusDto,
} from "src/models/dto/account.dto";
import { GetLanguagesDto } from "src/models/dto/language.dto";
import { GetCurrenciesDto } from "src/models/dto/currency.dto";
import { GetCountriesDto } from "src/models/dto/country.dto";
import {
  AccountStatusCheckbox,
  ICheckboxStatus,
} from "./AccountStatusCheckbox";
import {
  AccountStatusLog,
  AccountStatusType,
} from "src/models/app/AccountStatusLog";
import { AccountLimits } from "./AccountLimits/AccountLimits";
import { AccountDepositLimits } from "./AccountLimits/AccountDepositLimits";
import AccountComments from "./AccountComments/AccountComments";
import { AccountTags } from "./AccountTags/AccountTags";
import { getTagColor, Tags } from "src/models/app/Tag";
import { KYCTags } from "./KYC/KYC";
import { AccountTag } from "src/models/app/AccountTag";
import AccountTransactions from "./Transactions/AccountTransactions";
import GameTransactions from "./Transactions/GameTransactions";
import { LoginHistoryPage } from "../LoginHistory/LoginHistoryPage";
import KPI from "./KPI/KPI";
import AccountVipInfo from "./VIP/AccountVipInfo";
import StatusLogs from "./StatusLogs/StatusLogs";
import ManualBalanceAdjustment from "./ManualBalanceAdjustment/ManualBalanceAdjustment";
import SpinnerComp from "src/components/spinner/SpinnerComp";
import AccountBonusInfo from "./Bonus/AccountBonusInfo";
import Config from "src/config";

class AccountEditPageRefreshers {
  refreshComments: boolean;
  refreshAccount: boolean;
}

const BrandURLS: Record<string, string> = { 
  EPIC: Config.BACKOFFICE_FRONTEND_URLS.EPIC, 
  GAMI: Config.BACKOFFICE_FRONTEND_URLS.GAMI, 
  LOKE: Config.BACKOFFICE_FRONTEND_URLS.LOKE, 
  FREJA: Config.BACKOFFICE_FRONTEND_URLS.FREJA, 
};

const AccountEditPage: FunctionComponent = () => {
  const { accountId, focusedTab } = useParams<{
    accountId?: string;
    focusedTab?: string;
  }>();
  const [account, setAccount] = useState<Account>();
  const [languages, setLanguages] = useState<Language[]>([]);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loginInfo, setLoginInfo] = useState<ResponseGetAccountLoginInfoDto>();
  const [accountStatus, setAccountStatus] =
    useState<RequestSetAccountStatusDto>();
  const [emailMarketingStatus, setEmailMarketingStatus] =
    useState<RequestSetAccountStatusDto>();
  const [smsMarketingStatus, setSmsMarketingStatus] =
    useState<RequestSetAccountStatusDto>();

  const [blockedWithdrawalsStatus, setBlockedWithdrawalsStatus] =
    useState<RequestSetAccountStatusDto>();

  const [registeredOnOtherBrands, setRegisteredOnOtherBrands] = useState<
    RegisteredBrands[]
  >([]);

  const [blockedDeposits, setBlockedDeposits] = useState<boolean>();

  const [loading, setLoading] = useState<boolean>(false);

  const [refreshers, setRefreshers] = useState<AccountEditPageRefreshers>({
    refreshAccount: true,
    refreshComments: true,
  });
  const [readonlyWagering, setReadonlyWagering] = useState<boolean>(true);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        const languages: Language[] = await makeRequest<
          Language[],
          GetLanguagesDto
        >("post", urls.LANGUAGES_LIST);

        setLanguages(languages);
        const currencies: Currency[] = await makeRequest<
          Currency[],
          GetCurrenciesDto
        >("post", urls.CURRENCIES_LIST);

        setCurrencies(currencies);

        const countries: Country[] = await makeRequest<
          Country[],
          GetCountriesDto
        >("post", urls.COUNTRIES_LIST);

        setCountries(countries);

        const loginInfo: ResponseGetAccountLoginInfoDto = await makeRequest<
          ResponseGetAccountLoginInfoDto,
          RequestGetAccountLoginInfoDto
        >("post", urls.ACCOUNTS_LOGIN_INFO, { accountId: Number(accountId) });

        setLoginInfo(loginInfo);
        setRefreshers({
          refreshComments: true,
          refreshAccount: true,
        });
      } catch (err) {
        toastr.error((err as any).message);
      }
    };

    getData();
  }, [accountId]);

  const refreshAccount = async (): Promise<void> => {
    const response: ResponseGetAccountDto = await makeRequest<
      ResponseGetAccountDto,
      RequestGetAccountDto
    >("post", urls.ACCOUNTS_GET, {
      accountId: Number(accountId),
    });

    const accountStatusResponse = await makeRequest<
      ResponseGetAccountStatusDto,
      RequestGetAccountStatusDto
    >("post", urls.ACCOUNT_STATUS_LIST, {
      accountId: Number(response.account.id),
      documentNumber: response.account.documentNumber,
      checkSpelpaus: true,
    });

    let account: Account | undefined = response.account;

    if (!account) {
      account = new Account();
      account.name = "NewName";
      account.country = countries[0];
      account.language = languages[0];
      account.currency = currencies[0];
    }

    account.smsMarketing = accountStatusResponse.smsMarketing
      ? accountStatusResponse?.smsMarketing.status
      : account.smsMarketing;
    account.emailMarketing = accountStatusResponse.emailMarketing
      ? accountStatusResponse?.emailMarketing.status
      : account.emailMarketing;

    setAccount(account);
    setRegisteredOnOtherBrands(response.registeredOnOtherBrands);
    setBlockedDeposits(
      account.accountTags.some((tag: AccountTag) => {
        return tag.tagId === Tags.BLOCKED_DEPOSIT && tag.deletedAt === null;
      }),
    );
    setRefreshers({ ...refreshers, refreshAccount: false });
  };

  useEffect(() => {
    if (accountId && refreshers?.refreshAccount) {
      refreshAccount();
    }
  }, [accountId, refreshers?.refreshAccount]);

  const saveAccount = async (): Promise<void> => {
    if (account) {
      try {
        setLoading(true);
        if (accountStatus && accountStatus.active !== account.active) {
          await makeRequest<AccountStatusLog, RequestSetAccountStatusDto>(
            "post",
            urls.ACCOUNT_STATUS,
            accountStatus,
          );
          account.active = accountStatus?.active;
        }

        if (
          emailMarketingStatus &&
          emailMarketingStatus.active !== account.emailMarketing
        ) {
          await makeRequest<AccountStatusLog, RequestSetAccountStatusDto>(
            "post",
            urls.ACCOUNT_STATUS,
            emailMarketingStatus,
          );

          if (emailMarketingStatus.type === AccountStatusType.EMAIL_MARKETING)
            account.emailMarketing = emailMarketingStatus?.active;
        }

        if (
          smsMarketingStatus &&
          smsMarketingStatus.active !== account.smsMarketing
        ) {
          await makeRequest<AccountStatusLog, RequestSetAccountStatusDto>(
            "post",
            urls.ACCOUNT_STATUS,
            smsMarketingStatus,
          );

          if (smsMarketingStatus.type === AccountStatusType.SMS_MARKETING)
            account.smsMarketing = smsMarketingStatus?.active;
        }

        if (
          blockedWithdrawalsStatus &&
          blockedWithdrawalsStatus.active !== account.blockedWithdrawals
        ) {
          await makeRequest<AccountStatusLog, RequestSetAccountStatusDto>(
            "post",
            urls.ACCOUNT_STATUS,
            blockedWithdrawalsStatus,
          );

          if (
            blockedWithdrawalsStatus.type ===
            AccountStatusType.BLOCKED_WITHDRAWALS
          )
            account.blockedWithdrawals = blockedWithdrawalsStatus?.active;
        }

        if (!account.phoneNumber) {
          account.phoneNumber = undefined;
        } else {
          if (!account.phoneNumber.startsWith("467")) {
            toastr.error("Please Enter a valid Number Starting with 467");
            return;
          } else if (account.phoneNumber.length < 10) {
            toastr.error("Please Make sure number has more than 10 digits");
            return;
          }
        }

        await makeRequest<Account, RequestUpsertAccountDto>(
          "post",
          urls.ACCOUNTS_UPDATE,
          {
            ...account,
            dateOfBirth: DayJS(account.dateOfBirth).format("YYYY-MM-DD"),
            blockedDeposits: blockedDeposits,
          },
        );
        toastr.success("Account Updated!");
      } catch (err) {
        toastr.error((err as any).message);
      } finally {
        setLoading(false);
      }
      window.location.reload();
    }
  };

  function getComments(): React.ReactChild {
    if (!account) {
      return <></>;
    }

    return (
      <AccountComments account={account} refresh={refreshers.refreshComments} />
    );
  }

  function getTags(): React.ReactChild {
    if (!account) {
      return <></>;
    }

    return (
      <AccountTags account={account} refresh={refreshers.refreshAccount} />
    );
  }

  if (account === undefined) {
    return (
      <Container className="w-100 text-center">
        <SpinnerComp animation="border" />
      </Container>
    );
  }

  const renderTooltip = (text: string) => (
    <Tooltip id="button-tooltip">{text}</Tooltip>
  );

  const getActiveTags = () => {
    if (!account) {
      return <></>;
    }

    return account.accountTags
      .filter((tag) => tag.deletedAt === null)
      .map((accountTag) => {
        return (
          <span key={`tag-${accountTag.tag.id}`} className="p-1">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip(accountTag.tag.description)}
            >
              <Badge variant={getTagColor(accountTag.tagId)} bsPrefix="">
                {accountTag.tag.name}
              </Badge>
            </OverlayTrigger>
          </span>
        );
      });
  };

  return loading ? (
    <div className="d-flex justify-content-center">
      <SpinnerComp animation="border" />
    </div>
  ) : (
    <Container className="m-0 mw-none w-full pt-4">
      <Row>
        <Col md="auto">
          <Card className="w-fit p-2 mb-4">
            <h5>{account.name}</h5>
            {registeredOnOtherBrands.map((brand: RegisteredBrands) => (
              <a href={`${BrandURLS[brand.brand]}/accounts/edit/${brand.id}`}>
                <Badge
                  variant="warning"
                  key={brand.brand}
                  style={{ margin: "5px" }}
                >
                  {brand.brand}
                </Badge>
              </a>
            ))}  
          </Card>
        </Col>
        <Col>{getActiveTags()}</Col>
      </Row>

      <Tabs
        defaultActiveKey={focusedTab ?? "details"}
        id="uncontrolled-tab-example"
      >
        <Tab eventKey="details" title="Details">
          <Card>
            <Card.Header>
              {accountId === undefined ? "Create" : "Edit"} Account
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} controlId="formId">
                    <Form.Label>User Id</Form.Label>
                    <Form.Control readOnly type="text" value={account.id} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter account name"
                      value={account.name}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          setAccount({ ...account, name: event.target.value });
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter account email"
                      value={account.email}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          setAccount({ ...account, email: event.target.value });
                        }
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formAddress">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter address"
                      value={account.address}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          setAccount({
                            ...account,
                            address: event.target.value,
                          });
                        }
                      }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formCity">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter city"
                      value={account.city}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          setAccount({ ...account, city: event.target.value });
                        }
                      }}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="zipCode">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      value={account.zipcode}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          setAccount({
                            ...account,
                            zipcode: event.target.value,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="dateOfBirth">
                    <Form.Label>Date of Birth</Form.Label>

                    <DatePicker
                      value={DayJS(account.dateOfBirth, {
                        format: "YYYY-MM-DD",
                      }).toDate()}
                      onChange={(dates: Date | Date[]): void => {
                        let date = dates instanceof Array ? dates[0] : dates;
                        date = DayJS(date, { format: "YYYY-MM-DD" }).toDate();

                        if (account) {
                          setAccount({
                            ...account,
                            dateOfBirth: date,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formBalance">
                    <Form.Label>Balance</Form.Label>
                    <Form.Control
                      readOnly
                      type="text"
                      value={account.balance}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={12}
                      value={account.phoneNumber}
                      placeholder={"Not Specified"}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          setAccount({
                            ...account,
                            phoneNumber: event.target.value,
                          });
                        }
                      }}
                    />
                  </Form.Group>
                  {account.wagering && (
                    <Form.Group as={Col} controlId="formWagering">
                      <Form.Label>Wagering</Form.Label>
                      <InputGroup>
                        <Form.Control
                          readOnly={readonlyWagering}
                          type="number"
                          value={account.wagering.withdrawWager}
                          onChange={(e) => {
                            if (account) {
                              setAccount({
                                ...account,
                                wagering: {
                                  ...account.wagering,
                                  withdrawWager: parseInt(e.target.value),
                                },
                              });
                            }
                          }}
                        />
                        <Button
                          variant="secondary"
                          id="button-addon2"
                          onClick={() => setReadonlyWagering(!readonlyWagering)}
                        >
                          Edit
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  )}
                  {!account.wagering && (
                    <Form.Group as={Col} controlId="formWagering">
                      <Form.Label>Wagering</Form.Label>
                      <InputGroup>
                        <Form.Control
                          readOnly={readonlyWagering}
                          type="number"
                          value={0}
                          onChange={(e) => {
                            if (account) {
                              setAccount({
                                ...account,
                                wagering: {
                                  ...account.wagering,
                                  withdrawWager: parseInt(e.target.value),
                                },
                              });
                            }
                          }}
                        />
                        <Button
                          variant="secondary"
                          id="button-addon2"
                          onClick={() => setReadonlyWagering(!readonlyWagering)}
                        >
                          Edit
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  )}
                  <Form.Group as={Col} controlId="formDocNumber">
                    <Form.Label>Document Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Document number"
                      value={account.documentNumber}
                      readOnly
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="language">
                    <Form.Label>Language</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={account.language?.id || 0}
                      onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          if (evt.target) {
                            const newLanguage: number = parseInt(
                              evt.target.value,
                            );
                            setAccount({
                              ...account,
                              language: languages.filter(
                                (language: Language) =>
                                  language.id === newLanguage,
                              )[0],
                            });
                          }
                        }
                      }}
                    >
                      {languages.map((language: Language) => (
                        <option key={language.id} value={language.id}>
                          {language.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} controlId="country">
                    <Form.Label>Country</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={account.country?.id}
                      onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          if (evt.target) {
                            const newCountry: number = parseInt(
                              evt.target.value,
                            );
                            setAccount({
                              ...account,
                              country: countries.filter(
                                (country: Country) => country.id === newCountry,
                              )[0],
                            });
                          }
                        }
                      }}
                    >
                      {countries.map((country: Country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>{" "}
                  <Form.Group as={Col} controlId="currency">
                    <Form.Label>Currency</Form.Label>
                    <Form.Control
                      as="select"
                      custom
                      value={account.currency?.id}
                      onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          if (evt.target) {
                            const newCurrency: number = parseInt(
                              evt.target.value,
                            );
                            setAccount({
                              ...account,
                              currency: currencies.filter(
                                (currency: Currency) =>
                                  currency.id === newCurrency,
                              )[0],
                            });
                          }
                        }
                      }}
                    >
                      {currencies.map((currency: Currency) => (
                        <option key={currency.id} value={currency.id}>
                          {currency.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  {account ? (
                    <>
                      <Form.Group as={Col} controlId="accountActive">
                        <AccountStatusCheckbox
                          textShown="Active?"
                          account={account}
                          active={account.active}
                          type={AccountStatusType.ACCOUNT}
                          onChange={async (
                            checkboxStatus: ICheckboxStatus,
                          ): Promise<void> => {
                            setAccountStatus({
                              accountId: account.id,
                              type: AccountStatusType.ACCOUNT,
                              active: checkboxStatus.active,
                              reason: checkboxStatus.reason,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="accountEmailMarketingActive"
                      >
                        <AccountStatusCheckbox
                          textShown="Newsletter?"
                          account={account}
                          active={account.emailMarketing}
                          type={AccountStatusType.EMAIL_MARKETING}
                          onChange={async (
                            checkboxStatus: ICheckboxStatus,
                          ): Promise<void> => {
                            setEmailMarketingStatus({
                              accountId: account.id,
                              type: AccountStatusType.EMAIL_MARKETING,
                              active: checkboxStatus.active,
                              reason: checkboxStatus.reason,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="accountSmsMarketingActive"
                      >
                        <AccountStatusCheckbox
                          textShown="SMS?"
                          account={account}
                          active={account.smsMarketing}
                          type={AccountStatusType.SMS_MARKETING}
                          onChange={async (
                            checkboxStatus: ICheckboxStatus,
                          ): Promise<void> => {
                            setSmsMarketingStatus({
                              accountId: account.id,
                              type: AccountStatusType.SMS_MARKETING,
                              active: checkboxStatus.active,
                              reason: checkboxStatus.reason,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="accountBlockedWithdrawals"
                      >
                        <AccountStatusCheckbox
                          textShown="Withdrawals blocked"
                          account={account}
                          active={account.blockedWithdrawals}
                          type={AccountStatusType.BLOCKED_WITHDRAWALS}
                          onChange={async (
                            checkboxStatus: ICheckboxStatus,
                          ): Promise<void> => {
                            setBlockedWithdrawalsStatus({
                              accountId: account.id,
                              type: AccountStatusType.BLOCKED_WITHDRAWALS,
                              active: checkboxStatus.active,
                              reason: checkboxStatus.reason,
                            });
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="accountBlockedDeposits">
                        <Form.Check
                          type="checkbox"
                          label="Blocked Deposits"
                          checked={blockedDeposits}
                          onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>,
                          ): void => {
                            if (account) {
                              if (evt.target) {
                                const blockedDeposits: boolean =
                                  evt.target.checked;
                                setBlockedDeposits(blockedDeposits);
                              }
                            }
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="accountEmailVerified">
                        <Form.Check
                          type="checkbox"
                          label="Email Verified"
                          checked={account.emailVerified}
                          onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>,
                          ): void => {
                            if (account) {
                              if (evt.target) {
                                const emailVerified: boolean =
                                  evt.target.checked;
                                setAccount({
                                  ...account,
                                  emailVerified,
                                });
                              }
                            }
                          }}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="accountPhoneVerified">
                        <Form.Check
                          type="checkbox"
                          label="Phone Verified"
                          checked={account.phoneVerified}
                          onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>,
                          ): void => {
                            if (account) {
                              if (evt.target) {
                                const phoneVerified: boolean =
                                  evt.target.checked;
                                setAccount({
                                  ...account,
                                  phoneVerified,
                                });
                              }
                            }
                          }}
                        />
                      </Form.Group>
                    </>
                  ) : (
                    <></>
                  )}
                  <Form.Group as={Col} controlId="accountTest">
                    <Form.Check
                      type="checkbox"
                      label="Is Test Account"
                      checked={account.isTest === 1}
                      onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (account) {
                          if (evt.target) {
                            if (account) {
                              const isTest: boolean = evt.target.checked;
                              setAccount({
                                ...account,
                                isTest: isTest ? 1 : 0,
                              });
                            }
                          }
                        }
                      }}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col} controlId="formId">
                    <Form.Label>Last Login</Form.Label>
                    <Form.Control
                      readOnly
                      type="text"
                      value={loginInfo?.lastLoginDate ?? "-"}
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formId">
                    <Form.Label>Registration Date</Form.Label>
                    <Form.Control
                      readOnly
                      type="text"
                      value={loginInfo?.registrationDate ?? "-"}
                    />
                  </Form.Group>
                </Form.Row>

                <Button variant="primary" onClick={saveAccount}>
                  Save
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey="limits" title="Limits">
          <AccountLimits
            refreshAccount={(): void =>
              setRefreshers({ ...refreshers, refreshAccount: true })
            }
            account={account}
            onCommentUpdated={(): void => {
              setRefreshers({
                ...refreshers,
                refreshComments: false,
              });
            }}
          />
        </Tab>

        <Tab eventKey="deposit_limits" title="Deposit limits">
          <AccountDepositLimits
            refreshAccount={(): void =>
              setRefreshers({ ...refreshers, refreshAccount: true })
            }
            account={account}
          />
        </Tab>
        <Tab eventKey="comments" title="Comments">
          {getComments()}
        </Tab>
        <Tab eventKey="tags" title="Tags">
          {getTags()}
        </Tab>
        <Tab eventKey="kyc" title="KYC">
          {
            <KYCTags
              account={account}
              refreshAccount={(): void =>
                setRefreshers({ ...refreshers, refreshAccount: true })
              }
            ></KYCTags>
          }
        </Tab>
        <Tab eventKey="account_transactions" title="Monetary Transactions">
          {<AccountTransactions account={account}></AccountTransactions>}
        </Tab>
        <Tab eventKey="game_transactions" title="Game Transactions">
          {<GameTransactions account={account}></GameTransactions>}
        </Tab>
        <Tab eventKey="login_history" title="Login History">
          {<LoginHistoryPage />}
        </Tab>
        <Tab eventKey="kpi" title="KPI">
          {<KPI account={account} />}
        </Tab>
        <Tab eventKey="vipInfo" title="VIP Info">
          {<AccountVipInfo accountId={account?.id} />}
        </Tab>
        <Tab eventKey="bonus" title="Bonus">
          {<AccountBonusInfo account={account} />}
        </Tab>
        <Tab eventKey="statusLogsInfo" title="Status logs">
          {<StatusLogs account={account} />}
        </Tab>
        <Tab
          eventKey="manualBalanceAdjustment"
          title="Manual Balance Adjustment"
        >
          {<ManualBalanceAdjustment account={account} />}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default AccountEditPage;
