import { createBrowserHistory } from "history";
import React, {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
} from "react";

import "./App.css";
import { Router, Route, Switch } from "react-router-dom";

import Navbar from "./components/navbar/Navbar";
import AccountsPage from "./pages/Accounts/AccountsPage";
import { CategoriesPage } from "./pages/Categories/CategoriesPage";
import GamesPage from "./pages/Games/GamesPage/GamesPage";
import urls from "./utilities/urls";
import { BackofficeAccountsPage } from "./pages/BackofficeAccounts/BackofficeAccountsPage";
import { Role, RoleAccessLevel } from "./models/admin/Role";
import { Button, Container } from "react-bootstrap";
import DefaultContainerWrapper from "./pages/Accounts/DefaultContainerWrapper";
import withLoading from "./withLoading";
import withLogin from "./withLogin";
import { ProvidersPage } from "./pages/Providers/ProvidersPage";
import DocumentsPage from "./pages/Document/DocumentsPage";
import { TransactionsPage } from "./pages/Transactions/TransactionsPage";
import CommentsPage from "./pages/Comments/CommentsPage";

const customHistory = createBrowserHistory();

const routes = [
  {
    element: <AccountsPage />,
    path: "/accounts",
    minimumRequiredAccess: RoleAccessLevel.CustomerSupport,
  },
  {
    element: <CommentsPage />,
    path: "/comments",
    minimumRequiredAccess: RoleAccessLevel.CustomerSupport,
  },
  {
    element: <DocumentsPage />,
    path: "/documents",
    minimumRequiredAccess: RoleAccessLevel.CustomerSupport,
  },
  {
    element: <GamesPage />,
    path: "/games",
    minimumRequiredAccess: RoleAccessLevel.Developer,
  },
  {
    element: <CategoriesPage />,
    path: "/categories",
    minimumRequiredAccess: RoleAccessLevel.Developer,
  },
  {
    element: <ProvidersPage />,
    path: "/providers",
    minimumRequiredAccess: RoleAccessLevel.Developer,
  },
  {
    element: <BackofficeAccountsPage />,
    path: "/backoffice-accounts",
    minimumRequiredAccess: RoleAccessLevel.Admin,
  },
  {
    element: <TransactionsPage />,
    path: "/transactions",
    minimumRequiredAccess: RoleAccessLevel.PaymentAdministrator,
  },
];

const App: FunctionComponent<{
  roles: Role[];
  children?: ReactNode;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}> = (props: {
  roles: Role[];
  children?: ReactNode;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}) => {
  const highestAccessRole = props.roles.sort(
    (a, b) => b.accessLevel - a.accessLevel,
  )[0];

  return (
    <>
      {highestAccessRole ? (
        <>
          <Navbar
            setLoggedIn={props.setLoggedIn}
            accountHighestRole={highestAccessRole}
          />
          <Router history={customHistory}>
            <Switch>
              {routes
                .filter(
                  (route) =>
                    highestAccessRole.accessLevel >=
                    route.minimumRequiredAccess,
                )
                .map((route, index) => (
                  <Route key={index} path={route.path}>
                    {route.element}
                  </Route>
                ))}
            </Switch>
          </Router>
        </>
      ) : (
        <DefaultContainerWrapper>
          <Container className="text-center pt-2">
            No roles set up for this account. Please contact Administrator.
          </Container>
          <Container className="text-center pt-2">
            <Button
              onClick={(): void => {
                localStorage.removeItem("session-token");
                props.setLoggedIn(false);
              }}
            >
              Logout
            </Button>
          </Container>
        </DefaultContainerWrapper>
      )}
    </>
  );
};

export default withLogin(withLoading(App, urls.GET_ACCOUNT_ROLES));
